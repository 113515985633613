import ScrollHint from 'scroll-hint';

const [w, d, l] = [window, document, location];

export default class Utility {
    constructor() {
        this.init();
    }
    init() {
        new ScrollHint('.js-scrollable', {
            // suggestiveShadow: true,//影をつける
            i18n: {
                scrollable: 'スクロールできます',
            },
        });

        //page top animation
        const $fixed_btn = $('._fixed-btn');
        // $fixed_btn.hide();
        $(window).on('scroll', function () {
            if ($(this).scrollTop() > 150) {
                $fixed_btn.addClass('js-scroll');
            } else {
                $fixed_btn.removeClass('js-scroll');
            }
        });
        // $totop_btn.on('click', function () {
        //     $('body,html').animate({ scrollTop: 0 }, 800);
        //     return false;
        // });

        // current page highlight
        const $cr_navi = $('.gnav li a');
        $cr_navi.each(function () {
            const this_href = $(this).attr('href');
            const abs_path = $(this).attr('href', this_href).get(0).href;
            if (abs_path === l.href) {
                $(this).addClass('is-current');
            }
            //header内のみ親階層もcurrentを付ける
            if ($(this).parents('header').length) {
                if (l.href.indexOf(abs_path) != -1) {
                    $(this).addClass('is-current');
                }
            }
        });

        //外部リンク設定
        const newtabConfig = {
            //下階層で外部リンクに設定したい階層を設定してください。
            // 例） internal:['/directory/', '/fugafuga/'],
            internal: [],
            //外部のURLだが別タブにしたくないURLを設定してください。
            // 例） ignore:['https://example.com', 'http://hoge.co.jp'],
            ignore: [],
        };
        const siteDomain = document.domain;
        const siteDomainRegex = new RegExp(`^https?:\/\/(www\.)?${siteDomain}`);
        const internalRegex = () => {
            if (
                newtabConfig.internal === null ||
                newtabConfig.internal.length === 0
            ) {
                return false;
            }
            return new RegExp(newtabConfig.internal.join('|'));
        };
        const ignoreRegex = () => {
            if (
                newtabConfig.ignore === null ||
                newtabConfig.ignore.length === 0
            ) {
                return false;
            }
            return new RegExp(newtabConfig.ignore.join('|'));
        };
        const isHrefNewtab = (href) => {
            if (internalRegex()) {
                return href.match(internalRegex()) ? true : false;
            } else {
                return false;
            }
        };
        const isHrefExternal = (href) => {
            const isFullPath = /^https?/.test(href);
            if (ignoreRegex()) {
                if (href.match(ignoreRegex())) {
                    return false;
                }
            }
            const isExternalURL =
                href.match(siteDomainRegex) === null && isFullPath;
            return isExternalURL ? true : false;
        };
        $('a').each(function () {
            const hrefAttr = $(this).attr('href');
            if (isHrefExternal(hrefAttr) || isHrefNewtab(hrefAttr)) {
                $(this)
                    .addClass('externalLink')
                    .attr('target', '_blank')
                    .attr('rel', 'noopener');
            }
        });

        //PDF target brank
        $('a[href$=".pdf"],a[href$=".asx"]').each(function () {
            $(this)
                .addClass('pdfLink')
                .attr('target', '_blank')
                .attr('rel', 'noopener');
        });

        // 電話番号設定
        $('[data-tellink]').each(function () {
            const telLink = $(this).attr('data-tellink');
            $(this).wrapInner('<a href="tel:' + telLink + '"></a>');
        });
    }

    //URLによる読み込み分岐
    loader(loaderSwitch) {
        let path = location.pathname;
        loaderSwitch(path);
    }
}

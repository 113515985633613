export default class COMMON {
    init() {
        //ハンバーガーメニュー
        const $drowerBtn = $('.drawer_tab');
        const $drowerMenu = $('.drawer_wrap');
        $drowerBtn.on('click', function (e) {
            $(this).toggleClass('active');
            $drowerMenu.toggleClass('open');
            return false;
        });

        window.setTimeout(function () {
            $drowerMenu.addClass('js-load');
        }, 1000);

        //アコーディオン
        const $qaQuestion = $('._question');
        const $qaAnswer = $('._answer');
        $qaAnswer.hide();
        $qaQuestion.on('click', function () {
            $(this).toggleClass('is-active');
            $(this).next().slideToggle();
        });

        // 交差を検知したい要素
        const lazydisp = document.querySelectorAll('[data-lazydisp]');
        // IntersectionObserverのオプションを設定
        const options = {
            root: null,
            rootMargin: '20% 0px -20%',
            threshold: 0, // 閾値は0
        };

        // IntersectionObserverを呼び出し
        const observer = new IntersectionObserver(showElement, options);

        // lazydispクラスが付与されてる要素にたどり着いたらIntersectionObserverを実行
        lazydisp.forEach((elm) => {
            elm.classList.add('lazyhide');
            observer.observe(elm);
        });

        // 要素が表示されたら実行する動作
        function showElement(entries) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // 要素にactiveクラスを付与
                    entry.target.classList.remove('lazyhide');
                }
            });
        }
    }
}

import Swiper from 'swiper/bundle';

const [d] = [document];

class Blocks {
    mainvisual() {
        const mySwiper = new Swiper(
            '.mainvisual-slider[data-plugin="mainSlider"]',
            {
                effect: 'fade',
                fadeEffect: {
                    crossFade: true,
                },
                autoplay: {
                    delay: 4000,
                    disableOnInteraction: false,
                },
                loop: true,
                loopAdditionalSlides: 1,
                speed: 1500,
            }
        );
    }
    newsslider() {
        const swiper = new Swiper(
            '.topics-slider[data-plugin="topicsSlider"]',
            {
                slidesPerView: 'auto',
                // slidesPerView: 1,
                loop: true,
                spaceBetween: 24,
                grabCursor: true,
                pagination: {
                    el: '.topics-slider .swiper-pagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.topics-slider .swiper-button-next',
                    prevEl: '.topics-slider .swiper-button-prev',
                },
            }
        );
    }
}

const domReady = (TPL) => {
    let blocks = new Blocks();
    window.addEventListener('load', () => {
        blocks.mainvisual();
        blocks.newsslider();
    });
};

const jqInit = () => false;

export default function HOME() {
    // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
    d.addEventListener('DOMContentLoaded', domReady());
    $(() => jqInit());
}
